import Vue from 'vue';
import Vuex from 'vuex';
import firebase from 'firebase';
import router from '../router'
import axios from 'axios';
import autophp from '@/plugins/_autophp.js'
let sdb = new autophp();
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
// NUXT  tambahkan process.browser
// if (process.browser){
//   console.log(window.innerWidth, window.innerHeight);
// }

Vue.use(Vuex)
const createStore = () => {
  return new Vuex.Store({
    modules: {},
    // plugins: [new VuexPersistence().plugin],
    // plugins: [vuexLocal.plugin],
    state: {
      data:{
        listfavorites:[],
        menu:0
      },
      name:'',
      keranjang:0,
      mediaQ: 'XL',
      users: null,
      nossr: false,
      busy: false,
      isAuth:false
      //! ITEMS:
    },
    mutations: {
      goAuth(state) {
        state.auth.isAuth = true
      },
      vuex(state) {
        // alert('y')
        localStorage.setItem('vuex', JSON.stringify(state))
      },
      xeuv(state) {
        for (let key in state) {
          if (key != 'user') {
            state[key] = JSON.parse(localStorage.getItem('vuex'))[key]
          }
        }
      },
      changeUser(state, data) {
        state.user = data
      },
      changeUsers(state, data) {
        state.users = data
      },
      busy(state, data) {
        // alert('yup')
        state.busy = data
      },
      mediaQueries(state, data) {
        // console.log(data)
        state.mediaQ = data
      },
      nossr(state, data) {
        state.nossr = data
      },
      editData(state, data) {
        let index = data.index
        delete data.index
        let keys = Object.keys(data)
        keys.forEach(key => {
          // console.log(state.users[index][key]);
          state.users[index][key] = data[key]
        })
        console.log(state.users)
      },
      saveData(state, data) {
        let datas = {}
        let keys = Object.keys(state.users[0])
        keys.forEach(key => {
          // console.log(state.users[index][key]);
          datas[key] = data[key] ? data[key] : 'null'
        })
        state.users.push(datas)
      },
      deleteData(state, data) {
        state.users.splice(data, 1)
      },
      refreshData(state, data) {
        state.auth.user = data.users
        state.auth.user.picture = data.users.picture
        state.user = data.users
        // state.blog = data.blogs
        state.kategori = data.kategoris
        // state.notifikasi = data.notifikasis
      },
      logout(state) {
        // console.log(state)
        state.user = false
        state.users = false
        state.data = {
          listfavorites:[]
        }
        window.location.reload();
      },
      refreshUser(state, data) {
        state.user = data
      }
    },
    actions: {
       getData(vcon,data){ // persist data
        if(localStorage.getItem('data-persist')){
          vcon.state.data=JSON.parse(localStorage.getItem('data-persist'))
        }else{
        }
      },
      updateData(vcon){
        localStorage.setItem('data-persist',JSON.stringify(vcon.state.data))
      },
      auth() {
        if (
          localStorage.getItem('auth._token.local') == 'false' &&
          localStorage.getItem('auth.local') == 'false'
        ) {
          router.push('/')
        }
      },
     
      notifikasi(vcon, datas) {
        console.log('datas', datas)
        datas.createdAt = new Date();
        datas.dibaca = false
        // datas.to = datas.to ? JSON.parse(datas.to) :''
        sdb.collection("tbuser",false).doc().select(`select id,fcm from tbuser where id='${datas.to.id}'`).then(res => {
          console.log('cekfcm', res);
          let fcm = res[0].fcm

          let data = {
            data: {
              title: datas.title,
              body: datas.body,
              surveyID: "ewtawgreg-gragrag-rgarhthgbad",
              sound: true,
              ledColor: [255, 255, 128, 16],
              vibrationPattern: [250, 1000, 250, 500],
              link: datas.link,
              android_channel_id: "test-channel",
            },
            to: datas.to ? fcm : '-',
            priority: "high",
            content_available: true
          }
          console.log('data', data)
          datas.id_user = datas.to.id
          data = JSON.stringify(data)
          // data.to = data.to ? data.to :'-';
          // console.log('data',data)
          datas.to = datas.to ? datas.to : '';
          sdb.collection('notifikasi').doc().set({
            title:datas.title,
            body:datas.body,
            link:datas.link,
            createdAt:datas.createdAt,
            dibaca:false,
            id_user:datas.id_user
          }).then(res=>{
          })
          firebase.database().ref('/notif/sintal').set({id:datas.id_user,tanggal:new Date().getTime()}) //update

          axios.post("https://onesignal.com/api/v1/notifications",{
            app_id: "7d2f0b7a-c95b-47ef-be05-6215a554d49c",
            include_player_ids:[datas.to ? fcm : '-'],
            contents: {en: datas.body},
             android_channel_id:"9db143e1-f1a7-4909-86e9-26da7dd34813",
            headings:{en:datas.title},
            // url":"http",
            data: {"custom-data": "data1"},
            chrome_web_image:"https://balangankab.go.id/icon.png"
          },
          {
            headers:{
              Authorization:"Basic NzA0NDU4ZGItMTBlMC00NTZlLWEyYTMtMTAxNjJkMjg2N2Fm",
              "Content-Type":"application/json"
            }
          }
          ).then(res=>{

          })
        });
      },
      ceklogin({
        commit
      }, datas) {
        commit('changebusy', true)
        return new Promise((resolve, reject) => {
          firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
              console.log(user.uid)
              firebase.firestore().collection('users').doc(user.uid).get().then(res => {
                console.log(res.data())

                commit('changeUsers', res.data())
                localStorage.setItem('users', JSON.stringify(res.data()))
                if (datas) {
                  if (datas.true == true) {
                    commit('changebusy', false)
                  } else {
                    commit('changebusy', false)
                    router.push(datas.true)
                  }
                }
                resolve()
              })
            } else {
              console.log('user tidak ada');
              if (datas.false) {
                commit('changebusy', false)
                router.push(datas.false)
              } else {
                commit('changebusy', false)

              }
              // No user is signed in.
            }
          });
        })
      },
      logout(vcon) {
        vcon.commit('changegps', false)
        firebase
          .auth()
          .signOut()
          .then(() => {
            localStorage.setItem('expireDate', null)
            localStorage.setItem('auth._token.local', false)
            localStorage.setItem('auth.local', false)
            navigator.geolocation.clearWatch(vcon.state.watchgps);
            vcon.state.isAuth = false
            vcon.commit('logout', false)
            router.push('/login')
          });
        if (vcon.state.device != 'dekstop') {
          this.$auth.logout()
        }
      },
      refreshUser(vcon) {
        this.$axios.get('/api/auth/user').then(res => {
          console.log(res.data)
          vcon.commit('refreshUser', res.data.user)
        })
      },
      userRefresh(vcon) {
        vcon.commit('refreshUser', this.$auth.user)
      }
    }
  })
}

export default createStore